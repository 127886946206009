import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import HomePage from '../HomePage'
import SitizenPage from '../SitizenPage'
import SitizenInnerPage from '../SitizenInnerPage'
import ContactsPage from '../ContactsPage'
import PricePage from '../PricePage'

const page = ({ data, pageContext, location }) => {
    const { page } = data
    const {
        content,
        featuredImage,
        databaseId,
        uri,
        seo,
        isFrontPage,
        template: { templateName },
    } = page
    const { title } = seo
    const pageTitle = page.title
    let templateComponent = null

    switch (templateName) {
        case 'Archive Page':
            templateComponent = <SitizenPage data={page} pageContext={pageContext} location={location} />
            break
        case 'Inner Page':
            templateComponent = <SitizenInnerPage data={page} pageContext={pageContext} location={location} />
            break
        case 'Contacts Page':
            templateComponent = <ContactsPage data={page} pageContext={pageContext} location={location} />
            break
        case 'Price Page':
            templateComponent = <PricePage data={page} pageContext={pageContext} location={location} />
            break
        default:
            templateComponent = (
                <article className='global-container' id={`post-${databaseId}`}>
                    <h1 className='global-title-h1' dangerouslySetInnerHTML={{ __html: pageTitle }} />

                    <div className='post'>
                        <div className='post__content' dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </article>
            )
    }

    console.log('...page data start...')
    console.log(data)
    console.log(pageContext)
    console.log(location)
    console.log('...page data end...')

    return (
        <Layout pageContext={location.pathname}>
            <Seo
                title={title ? title : page.title}
                meta={seo}
                lang='en-US'
                // socialImage={featuredImage?.node}
                uri={uri}
            />

            {isFrontPage ? <HomePage data={page} /> : templateComponent}
        </Layout>
    )
}

export const query = graphql`
    query page($id: String!, $nextPage: String, $previousPage: String) {
        page: wpPage(id: { eq: $id }) {
            ...PageContent
        }
        nextPage: wpPage(id: { eq: $nextPage }) {
            title
            uri
        }
        previousPage: wpPage(id: { eq: $previousPage }) {
            title
            uri
        }
    }
`

export default page
