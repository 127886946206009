import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import * as styles from './about.module.scss'

const About = props => {
    const {
        aboutLeftSide: { aboutImage },
        aboutRightSide: { aboutTitle, aboutDescription, aboutLink },
    } = props.data
    const sectionImage = aboutImage ? getImage(aboutImage.localFile) : null

    if (!aboutImage && !aboutTitle && !aboutDescription) return null

    return (
        <section className={styles.wrapper + ' global-full-width'} id='section'>
            <div className='global-container'>
                <div
                    className={styles.column}
                    data-sal='slide-up'
                    data-sal-delay='250'
                    data-sal-duration='1800'
                    data-sal-easing='ease-out-back'
                >
                    {typeof sectionImage === 'object' && sectionImage !== null && (
                        <GatsbyImage image={sectionImage} alt={aboutTitle} />
                    )}
                </div>
                <div className={styles.column}>
                    <h2
                        data-sal='slide-up'
                        data-sal-delay='250'
                        data-sal-duration='1800'
                        data-sal-easing='ease-out-back'
                        className='global-title-h2'
                        dangerouslySetInnerHTML={{ __html: aboutTitle }}
                    />
                    <p
                        data-sal='slide-up'
                        data-sal-delay='350'
                        data-sal-duration='1800'
                        data-sal-easing='ease-out-back'
                        className='global-text'
                        dangerouslySetInnerHTML={{ __html: aboutDescription }}
                    />
                    {aboutLink.url && (
                        <Link
                            data-sal='slide-up'
                            data-sal-delay='450'
                            data-sal-duration='1800'
                            data-sal-easing='ease-out-back'
                            to={aboutLink.url}
                            target={aboutLink.target}
                            className='global-button global-button--rounded'
                        >
                            {aboutLink.title}
                        </Link>
                    )}
                </div>
            </div>
        </section>
    )
}

export default About
