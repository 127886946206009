import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Grid, Carousel } from 'antd'
import loadable from '@loadable/component'

import * as styles from './reviews.module.scss'

const ReviewItem = loadable(() => import('./ReviewItem'))

const Reviews = props => {
    const { allWpReview } = useStaticQuery(
        graphql`
            query {
                allWpReview {
                    nodes {
                        id
                        date(formatString: "DD MMMM, YYYY")
                        dateGmt
                        title
                        content
                        reviewSetting {
                            reviewRate
                        }
                    }
                }
            }
        `
    )
    const { useBreakpoint } = Grid
    const { sm } = useBreakpoint()

    return (
        <div className={styles.reviews + ' global-full-width'}>
            {allWpReview.nodes.length > 0 && (
                <div className='global-container'>
                    <h2
                        className='global-title-h2'
                        data-sal='slide-up'
                        data-sal-delay='250'
                        data-sal-duration='1500'
                        data-sal-easing='ease-out-back'
                    >
                        Customer Testimonials
                    </h2>
                    {!sm ? (
                        <Carousel
                            className={styles.slider + ' global-full-width'}
                            draggable={true}
                            slidesToScroll={1}
                            variableWidth={true}
                            easing='cubic-bezier(0.65, 0.05, 0.36, 1)'
                        >
                            {allWpReview.nodes.map((review, index) => (
                                <ReviewItem item={review} key={review.id} index={index} />
                            ))}
                        </Carousel>
                    ) : (
                        <div className={styles.list}>
                            {allWpReview.nodes.map((review, index) => (
                                <ReviewItem item={review} key={review.id} index={index} />
                            ))}
                        </div>
                    )}
                    <Link
                        to='/reviews'
                        className='global-button global-button--rounded'
                        data-sal='slide-up'
                        data-sal-delay='350'
                        data-sal-duration='1500'
                        data-sal-easing='ease-out-back'
                    >
                        More
                    </Link>
                </div>
            )}
        </div>
    )
}

export default Reviews
