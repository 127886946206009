import React from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'

import Form from '../Form'
import * as styles from './estimate.module.scss'

const EstimateCost = props => {
    const {
        calculatorData,
        data: { estimateTitle, estimateDescription },
        type = 'estimate',
    } = props

    return (
        <section className={styles.wrapper}>
            <h2 className={styles.title + ' global-title-h4'}>{estimateTitle && parse(estimateTitle)}</h2>
            <p className={styles.description}>{estimateDescription && parse(estimateDescription)}</p>
            <div className={styles.formWrapper}>
                <Form formId={`4c89d7a9-b2ca-4f2d-a4c2-bce9a67193c1`} formType={type} calculatorData={calculatorData} />
            </div>
        </section>
    )
}

EstimateCost.propTypes = {
    type: PropTypes.string,
    estimateTitle: PropTypes.string,
    estimateDescription: PropTypes.string,
}

EstimateCost.defaultProps = {
    type: 'estimate',
    estimateTitle: ``,
    estimateDescription: ``,
}

export default EstimateCost
