import React, { useState, useEffect, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Tabs } from 'antd'

import ArrowRightIcon from '../../assets/svg/arrow-right.inline.svg'
import * as styles from './residency.module.scss'

const ResidencyPages = ({ data, location }) => {
    const { residenceList } = data
    const { TabPane } = Tabs
    const isBrowser = typeof window !== 'undefined'
    const tabRef = useRef(null)
    let locationURL
    let locationCountryCode
    try {
        locationURL = location ? new URL(location.href) : null
        locationCountryCode = locationURL ? locationURL.searchParams.get('country') : 'all'
    } catch (error) {
        console.log(error)
    }
    const [pages, setPages] = useState([])
    const [currentTabKey, setCurrentTab] = useState(locationCountryCode)

    useEffect(() => {
        setPages(arr => [...arr, { all: residenceList.map(page => page) }])
        setPages(arr => [
            ...arr,
            { es: residenceList.filter(page => page.residencePage.countryFlagIcon.flag.split('.')[0] === 'es') },
            { pt: residenceList.filter(page => page.residencePage.countryFlagIcon.flag.split('.')[0] === 'pt') },
            { hu: residenceList.filter(page => page.residencePage.countryFlagIcon.flag.split('.')[0] === 'hu') },
            { nl: residenceList.filter(page => page.residencePage.countryFlagIcon.flag.split('.')[0] === 'nl') },
            { lv: residenceList.filter(page => page.residencePage.countryFlagIcon.flag.split('.')[0] === 'lv') },
            { lt: residenceList.filter(page => page.residencePage.countryFlagIcon.flag.split('.')[0] === 'lt') },
        ])
    }, [])

    useEffect(() => {
        const stateY = location.state && location.state.pageY ? location.state.pageY : null

        isBrowser && stateY && window.scroll(0, stateY)
    }, [location])

    const tabsChangeHandler = key => {
        setCurrentTab(key)
        isBrowser &&
            navigate(`?country=${key}`, {
                state: {
                    pageY: window.scrollY,
                },
            })
    }

    return (
        <section className={styles.wrapper} ref={tabRef}>
            <Tabs
                type='card'
                animated={{ inkBar: true, tabPane: true }}
                defaultActiveKey={currentTabKey}
                onChange={tabsChangeHandler}
                className={styles.tabs}
            >
                {pages.map(item => {
                    const key = Object.keys(item)[0]
                    const title =
                        key === 'all'
                            ? 'All countries'
                            : key === 'es'
                            ? 'Spain'
                            : key === 'pt'
                            ? 'Portugal'
                            : key === 'hu'
                            ? 'Hungary'
                            : key === 'nl'
                            ? 'Netherlands'
                            : key === 'lt'
                            ? 'Lithuania'
                            : key === 'lv'
                            ? 'Latvia'
                            : ''
                    const entries = item[key]

                    if (entries.length === 0) return

                    return (
                        <TabPane tab={title} key={key}>
                            {entries.map((page, index) => {
                                const title = page.residenceTitle
                                const description = page.residenceContent
                                const slidePage = page.residencePage

                                if (!slidePage) return

                                const flagCode = slidePage.countryFlagIcon?.flag
                                    ? slidePage.countryFlagIcon.flag.split('.')[0]
                                    : null
                                const flagImage = flagCode ? require(`../../assets/images/flags/${flagCode}.svg`) : null
                                const slideImage =
                                    slidePage.featuredImage && slidePage.featuredImage.node
                                        ? getImage(slidePage.featuredImage.node.localFile)
                                        : null

                                return (
                                    <div className={styles.listItem} key={flagCode + index}>
                                        <Link to={slidePage.uri}>
                                            <div className={styles.imgWrapper}>
                                                {slideImage ? (
                                                    <GatsbyImage image={slideImage} alt={title} />
                                                ) : (
                                                    <img
                                                        alt='image placeholder'
                                                        role='presentation'
                                                        src="data:image/svg+xml;charset=utf-8,%3Csvg height='270' width='390' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                                                    />
                                                )}
                                                {flagImage && (
                                                    <span className={styles.flag}>
                                                        <img
                                                            src={flagImage.default}
                                                            alt={title}
                                                            width='40'
                                                            height='30'
                                                            loading='lazy'
                                                        />
                                                    </span>
                                                )}
                                                <div
                                                    className={styles.content}
                                                    dangerouslySetInnerHTML={{ __html: description }}
                                                />
                                            </div>
                                            <div className={styles.itemContent}>
                                                <h3
                                                    className={styles.itemTitle}
                                                    dangerouslySetInnerHTML={{ __html: title }}
                                                />
                                                <span className={styles.itemMore}>
                                                    <span>More</span>
                                                    <ArrowRightIcon />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </TabPane>
                    )
                })}
            </Tabs>
        </section>
    )
}

export default ResidencyPages
